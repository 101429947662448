import { translate } from '@/domains/core/localization/translate';
export const {
  rootLabel
} = translate({
  "rootLabel": "Suggestions de la recherche"
});
export const {
  autocomplete,
  emptySearchB2C,
  emptySearchB2B,
  autocompleteB2B,
  searchHistory,
  deleteHistory,
  searchAI
} = {
  autocomplete: translate({
    "searchBrandTitle": "Top Marques"
  }),
  emptySearchB2C: translate({
    "searchBrandTitle": "Marques du moment",
    "searchCategoryTitle": "Cat\xE9gories du moment",
    "searchQueryTitle": "Recherches populaires"
  }),
  emptySearchB2B: translate({
    "searchBrandTitle": "Marques Pro",
    "searchCategoryTitle": "Cat\xE9gories du moment",
    "searchQueryTitle": "Recherches des Pros"
  }),
  autocompleteB2B: translate({
    "searchProSelection": "Top s\xE9lections des Pros"
  }),
  searchHistory: translate({
    "title": "Mes recherches r\xE9centes"
  }),
  deleteHistory: translate({
    "title": "Tout effacer"
  }),
  searchAI: translate({
    "title": "Exemples de recherches",
    "history": "Recherches r\xE9centes"
  })
};
import { translate, translateFormatter } from '@/core/localization/translate';
export const countdownDaysLeft = translateFormatter<{
  days: number;
}>("Plus que {days, plural, =1 {# jour} other {# jours}}", undefined, undefined, "fr");
export const countdownTimeLeft = translateFormatter<{
  hoursFormatted: string;
  minutesFormatted: string;
  secondsFormatted: string;
}>("{hoursFormatted}h {minutesFormatted}m {secondsFormatted}s", undefined, undefined, "fr");
export const {
  countdownDays,
  countdownHours,
  countdownMinutes,
  countdownSeconds
} = translate({
  "countdownDays": "JOURS",
  "countdownHours": "HEURES",
  "countdownMinutes": "MIN",
  "countdownSeconds": "SEC"
});
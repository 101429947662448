import { translate } from '@/domains/core/localization/translate';
export const {
  leonidasMenuExploreCatalogue,
  leonidasMenuLinksTitle,
  leonidasMenuProductsTitle,
  leonidasMenuSeeMoreTitle
} = translate({
  "leonidasMenuExploreCatalogue": "Explorer le catalogue",
  "leonidasMenuLinksTitle": "Notre Expertise",
  "leonidasMenuProductsTitle": "Produits",
  "leonidasMenuSeeMoreTitle": "Voir plus"
});
import { translate } from '@/domains/core/localization/translate';
export const {
  headerSearchB2C,
  headerSearchB2B,
  headerSearchCommon,
  headerSearchAI
} = {
  headerSearchB2C: translate({
    "label": "Effectuer une recherche sur ManoMano",
    "placeholder": "Rechercher un produit ou une marque",
    "placeholderMobile": "Rechercher sur ManoMano"
  }),
  headerSearchB2B: translate({
    "label": "Rechercher des produits et des marques",
    "placeholder": "Rechercher un produit ou une marque",
    "placeholderMobile": "Rechercher sur ManoMano"
  }),
  headerSearchCommon: translate({
    "delete": "Effacer",
    "magnifyingGlass": "Rechercher"
  }),
  headerSearchAI: translate({
    "placeholder": "Que recherchez-vous ?"
  })
};
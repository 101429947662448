import { translate } from '@/domains/core/localization/translate';
export const {
  defaultTopProductsTitle,
  // = mobile
  topProductsB2BTitle,
  topSalesTitle,
  lastSeenTitle,
  similarityTitle,
  favoriteProductsB2BTitle
} = translate({
  "defaultTopProductsTitle": "Top ventes",
  "topProductsB2BTitle": "Top produits Pro",
  "topSalesTitle": "Top ventes du moment",
  "lastSeenTitle": "Produits vus r\xE9cemment",
  "similarityTitle": "Ces articles peuvent vous int\xE9resser !",
  "favoriteProductsB2BTitle": "Suggestions de produits"
});
import { translate } from '@/core/localization/translate';
export const {
  footerLegalCompanyInformation,
  footerLegalCompanyAddress,
  footerLegalCompanyDetailsDescription,
  footerLegalCompanyDetailsTitle,
  footerLegalConsumptionInformation,
  footerLegalConsumptionInformationB2B,
  footerLegalConsumptionInformationHref,
  footerLegalConsumptionInformationHrefB2B,
  footerLegalManageCookies,
  footerLegalMentions,
  footerLegalMentionsHref,
  footerLegalMentionsHrefB2B,
  footerLegalProtection,
  footerLegalProtectionHref,
  footerLegalReports,
  footerLegalReportsHref,
  footerLegalTerms,
  footerLegalTermsHref,
  footerLegalTermsHrefB2B,
  footerLegalTrustCenter,
  footerLegalTrustCenterHref,
  footerSitemap,
  footerSitemapHref
} = translate({
  "footerLegalCompanyInformation": "footer.legal.company.information",
  "footerLegalCompanyAddress": "footer.company.address",
  "footerLegalCompanyDetailsDescription": "footer.company.details.description",
  "footerLegalCompanyDetailsTitle": "footer.company.details.title",
  "footerLegalConsumptionInformation": "Infos consommateurs",
  "footerLegalConsumptionInformationB2B": "Bewertungen auf der Plattform",
  "footerLegalConsumptionInformationHref": "https://cdn.manomano.com/legal/consumer-information/fr.pdf",
  "footerLegalConsumptionInformationHrefB2B": "https://cdn.manomano.com/legal/consumer-information/fr.pdf",
  "footerLegalManageCookies": "Param\xE9trer mes cookies",
  "footerLegalMentions": "Mentions l\xE9gales",
  "footerLegalMentionsHref": "https://cdn.manomano.com/legal/legal-notice/fr.pdf",
  "footerLegalMentionsHrefB2B": "https://cdn.manomano.com/legal/legal-notice/fr.pdf",
  "footerLegalProtection": "Protection des donn\xE9es",
  "footerLegalProtectionHref": "https://cdn.manomano.com/legal/data-protection/fr.pdf",
  "footerLegalTrustCenter": "Trust Center",
  "footerLegalTrustCenterHref": "https://trust.manomano.com/",
  "footerLegalReports": "Rapports",
  "footerLegalReportsHref": "/lp/rapport-de-transparence-fr-3226",
  "footerLegalTerms": "Conditions g\xE9n\xE9rales d'utilisation",
  "footerLegalTermsHref": "https://cdn.manomano.com/legal/terms-of-service/fr.pdf",
  "footerLegalTermsHrefB2B": "https://cdn.manomano.com/legal/terms-of-service/fr.pdf",
  "footerSitemap": "Plan du site",
  "footerSitemapHref": "/plan-du-site"
});
import { formatCurrency } from '@/domains/core/localization/currency';
import { translate } from '@/domains/core/localization/translate';
import { CURRENCY } from '@/domains/core/settings/constants';
import { SponsoredDetailedDescription, type SponsoredData } from '@/domains/productDiscovery/ProductCard/components/SponsoredDetailedDescription';
import { couponTag, freeDeliveryByMM, modelEnrichedTranslate, proPriceAdvantageTag, translations } from '@/domains/productDiscovery/ProductCard/translations';
import type { ProductCardListingsTranslations } from '@/productDiscovery/Listings/components/ProductCardListingsEnhancer/components/ProductCardListings/interfaces';
export const {
  freeDeliveryByMMReworded
} = translate({
  "freeDeliveryByMMReworded": "Livraison 48h gratuite"
});
const {
  ecoResponsibleLabel,
  madeInLabel,
  secondHandLabel,
  bulkRevamped,
  bestQualityOfferRevamped,
  options,
  loweredPriceLabel
} = translate({
  "ecoResponsibleLabel": "\xC9co-responsable",
  "madeInLabel": "Origine France",
  "secondHandLabel": "Seconde vie",
  "bulkRevamped": "Prix d\xE9gressifs",
  "options": "options",
  "bestQualityOfferRevamped": "Mano S\xE9lection",
  "loweredPriceLabel": "Prix"
});
const productCardListingsTranslations: ProductCardListingsTranslations = {
  ...translations,
  tags: {
    ...translations.tags,
    ecoResponsible: {
      label: ecoResponsibleLabel
    },
    madeIn: {
      label: madeInLabel
    },
    secondHand: {
      label: secondHandLabel
    },
    bulk: {
      label: bulkRevamped
    },
    bestQualityOffer: {
      label: bestQualityOfferRevamped
    },
    options: {
      label: options
    },
    loweredprice: {
      label: loweredPriceLabel
    }
  }
};
const cache: {
  [key: string]: string;
} = {};
const formatAndCacheModelTranslation = (modelsCount: number): string => {
  cache[modelsCount] = modelEnrichedTranslate.format({
    count: modelsCount
  }).toString();
  return cache[modelsCount];
};
for (let i = 1; i < 11; i += 1) {
  cache[i] = formatAndCacheModelTranslation(i);
}
export const getProductCardTranslations = (modelsCount: number | undefined, couponAmount?: string | undefined, mmfLabelReworded?: boolean, sponsoredData?: SponsoredData, proAdvantagePercentage?: number): ProductCardListingsTranslations => {
  productCardListingsTranslations.informationLine.alternativeModel = modelsCount !== undefined ? cache[modelsCount] ?? formatAndCacheModelTranslation(modelsCount) : '';
  if (couponAmount && productCardListingsTranslations?.tags.coupon) {
    productCardListingsTranslations.tags.coupon.label = `${formatCurrency(couponAmount, CURRENCY, {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0
    })} ${couponTag}`;
    productCardListingsTranslations.tags.coupon.labelShort = `-${formatCurrency(couponAmount, CURRENCY, {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0
    })}`;
  }
  translations.deliveryLabel.freeDeliveryByMM = mmfLabelReworded ? freeDeliveryByMMReworded : freeDeliveryByMM;
  if (sponsoredData && sponsoredData.adPayer && sponsoredData.brandName && sponsoredData.sellerName) {
    translations.tags.sponsored.description = () => SponsoredDetailedDescription({
      sponsoredData
    });
  }
  if (proAdvantagePercentage) {
    productCardListingsTranslations.tags = {
      ...productCardListingsTranslations.tags,
      proPriceAdvantage: {
        label: proPriceAdvantageTag.format({
          proAdvantagePercentage: Math.round(proAdvantagePercentage)
        }) as string
      }
    };
  }
  return productCardListingsTranslations;
};
import { RichTextFormatter } from '@/core/localization/translate';
import { translate, translateFormatter } from '@/domains/core/localization/translate';
import { footerLegalConsumptionInformationHrefB2B as realFooterLegalConsumptionInformationHrefB2B, footerLegalMentionsHrefB2B as realFooterLegalMentionsHrefB2B, footerLegalProtectionHref as realFooterLegalProtectionHref, footerLegalTermsHrefB2B as realFooterLegalTermsHrefB2B } from './modules/LegalData/translations';
export const footerLegalConsumptionInformationHrefB2B = realFooterLegalConsumptionInformationHrefB2B;
export const footerLegalMentionsHrefB2B = realFooterLegalMentionsHrefB2B;
export const footerLegalProtectionHref = realFooterLegalProtectionHref;
export const footerLegalTermsHrefB2B = realFooterLegalTermsHrefB2B;

// Contact Newsletter Footer/Modal
export const footerContactNewsletterCommon = {
  ...translate({
    "inputLabel": "Email"
  }),
  legal: undefined
};
export const footerContactNewsletter = translate({
  "title": "Envie de recevoir des (bons) conseils et des promos ?",
  "subtitle": "Inscrivez-vous \xE0 la newsletter ManoMano !",
  "subtextLink": "En savoir plus"
});
export const footerContactNewsletterIncentiveVariant = translate({
  "title": "Inscrivez-vous \xE0 notre newsletter et recevez 5\u20AC de bon d\u2019achat*, des conseils et des promos",
  "subtitle": "*Code promo envoy\xE9 par email \xE0 la suite de l\u2019inscription, valable pour une commande de 150\u20AC minimum d\u2019achat et pendant 1 mois \xE0 compter de sa r\xE9ception.",
  "subtextLink": "En savoir plus"
});
export const footerContactSubtextIncentiveVariant = translateFormatter<{
  br: RichTextFormatter;
}>("Votre email sera uniquement utilis\xE9 pour vous envoyer nos newsletters. <br></br>Vous pourrez vous d\xE9sabonner via le lien int\xE9gr\xE9 dans la newsletter.", undefined, undefined, "fr");
export const footerContactSubtext = translateFormatter<{
  br: RichTextFormatter;
}>("Votre email sera uniquement utilis\xE9 pour vous envoyer nos newsletters. <br></br>Vous pourrez vous d\xE9sabonner via le lien int\xE9gr\xE9 dans la newsletter.", undefined, undefined, "fr");
// Contact Newsletter Success Modal
export const contactNewsletterModalSuccess = translate({
  "title": "F\xE9licitations !",
  "subtitle": "\xC0 vous les bons plans et les promos in\xE9dites ! Vous allez pouvoir bricoler le portefeuille en paix"
});
export const contactNewsletterModalSuccessIncentiveVariant = translate({
  "title": "F\xE9licitations !",
  "subtitle": "\xC0 vous les bons plans et les promos in\xE9dites ! Vous allez pouvoir bricoler le portefeuille en paix"
});
export const {
  availablePlayStore,
  detailsApp,
  detailsProApp,
  downloadAppStore,
  footerAboutSubTitle,
  footerAboutTitle,
  footerAppStoreMamomanoProUrl,
  footerAppStoreMamomanoUrl,
  footerB2cConnectProLink,
  footerBestSellers,
  footerBestSellersHref,
  footerContactCountrySubTitle,
  footerContactCountryTitle,
  footerContactNewsletterInvalidError,
  footerContactNewsletterSubmitText,
  footerContactServiceCallPrice,
  footerContactServiceSubTitle,
  footerContactServiceTitle,
  footerContactSocialNetworkSubTitle,
  footerContactSocialNetworkTitle,
  footerContactSubTitle,
  footerContactTitle,
  footerManoExpress,
  footerManoExpressHref,
  footerManoExpressHrefB2B,
  footerNavigationAbout,
  footerNavigationAboutHref,
  footerNavigationAffiliation,
  footerNavigationAffiliationHref,
  footerNavigationBrands,
  footerNavigationBrandsHref,
  footerNavigationContactUs,
  footerNavigationContactUsHref,
  footerNavigationCouponLegalMention,
  footerNavigationCouponLegalMentionHref,
  footerNavigationHelp,
  footerNavigationHelpHref,
  footerNavigationOrders,
  footerNavigationOrdersHref,
  footerNavigationPressContact,
  footerNavigationPressContactHref,
  footerNavigationRecruitingSellers,
  footerNavigationRecruitingSellersHref,
  footerNavigationRecruitment,
  footerNavigationRecruitmentHref,
  footerPlaymarketMamomanoProUrl,
  footerPlaymarketMamomanoUrl,
  footerProButtonTitle,
  footerQualityPaymentTitle,
  footerReportContentDSALabel,
  footerReportContentDSALink,
  footerSitemapAdvice,
  footerSitemapAdviceHref,
  footerSitemapCurrentOffers,
  footerSitemapCurrentOffersHref,
  footerSitemapProductCategories,
  footerSitemapProductCategoriesHref,
  footerSitemapInspirations,
  footerSitemapInspirationsHref,
  footerSitemapTopProducts,
  footerSitemapTopProductsHref,
  footerToTopButtonAriaLabel,
  footerTrustProSubtitle,
  footerTrustProTitle,
  titleApp,
  titleProApp
} = translate({
  "availablePlayStore": "Disponible sur Google Play",
  "detailsApp": "+ 6 millions de produits dans votre poche",
  "detailsProApp": "* Notre application pour les professionnels",
  "downloadAppStore": "T\xE9l\xE9charger dans l'App Store",
  "footerAboutSubTitle": "Vous allez tout savoir par ici",
  "footerAboutTitle": "Conseils, produits, prix",
  "footerAppStoreMamomanoUrl": "https://apps.apple.com/fr/app/manomano-bricolage-et-jardin/id1503142603",
  "footerAppStoreMamomanoProUrl": "https://apps.apple.com/fr/app/manomano-pro/id1498180724",
  "footerB2cConnectProLink": "/se-connecter?b2b=true",
  "footerBestSellers": "Top ventes",
  "footerBestSellersHref": "/bs",
  "footerContactCountrySubTitle": "Et bient\xF4t pr\xE9sents sur Mars !",
  "footerContactCountryTitle": "International",
  "footerContactNewsletterInvalidError": "Cette valeur n'est pas une adresse email valide",
  "footerContactNewsletterSubmitText": "S'inscrire",
  "footerContactServiceCallPrice": "Prix d'un appel local.",
  "footerContactServiceSubTitle": "Allo Houston, ici ManoMano",
  "footerContactServiceTitle": "Service client",
  "footerContactSocialNetworkSubTitle": "La magie de l\u2019internet mondial !",
  "footerContactSocialNetworkTitle": "Rejoignez-nous",
  "footerContactSubTitle": "Coin renseignements",
  "footerContactTitle": "ManoMano infos",
  "footerNavigationAbout": "Qui sommes-nous ?",
  "footerNavigationAboutHref": "https://about.manomano.com",
  "footerNavigationAffiliation": "Programme d\u2019Affiliation",
  "footerNavigationAffiliationHref": "https://info.manomano.com/rejoignez-notre-programme/",
  "footerNavigationBrands": "Nos marques",
  "footerNavigationBrandsHref": "/marques",
  "footerNavigationContactUs": "Nous contacter",
  "footerNavigationContactUsHref": "/contact",
  "footerNavigationCouponLegalMention": "Conditions g\xE9n\xE9rales d\u2019utilisation des coupons",
  "footerNavigationCouponLegalMentionHref": "/lp/codes-promo-manomano-economisez-sur-vos-achats-avec-nos-offres-exclusives-501",
  "footerNavigationHelp": "Besoin d\u2019aide ?",
  "footerNavigationHelpHref": "/aide",
  "footerNavigationOrders": "Suivi de commande",
  "footerNavigationOrdersHref": "/mes-commandes",
  "footerNavigationPressContact": "Contact Presse",
  "footerNavigationPressContactHref": "https://about.manomano.com/fr/actualites/",
  "footerNavigationRecruitingSellers": "Devenir marchand",
  "footerNavigationRecruitingSellersHref": "/lp/devenez-marchand-2586",
  "footerNavigationRecruitment": "Nous rejoindre",
  "footerNavigationRecruitmentHref": "https://careers.manomano.jobs/",
  "footerPlaymarketMamomanoProUrl": "https://play.google.com/store/apps/details?id=com.manomano.pro&hl=fr",
  "footerPlaymarketMamomanoUrl": "https://play.google.com/store/apps/details?id=com.manomano&hl=fr",
  "footerProButtonTitle": "Acc\xE9der \xE0 votre espace Pro",
  "footerQualityPaymentTitle": "Paiement s\xE9curis\xE9",
  "footerReportContentDSALabel": "Notifier un contenu illicite",
  "footerReportContentDSALink": "https://survey.zohopublic.eu/zs/Y2DHs5?lang=fr",
  "footerSitemapAdvice": "Nos bons conseils",
  "footerSitemapAdviceHref": "/nos-conseils",
  "footerSitemapCurrentOffers": "Nos offres du moment",
  "footerSitemapInspirations": "Nos inspirations",
  "footerSitemapInspirationsHref": "/conseil/nos-inspirations-14912",
  "footerSitemapCurrentOffersHref": "/nos-offres-du-moment",
  "footerSitemapProductCategories": "Nos top cat\xE9gories de produits",
  "footerSitemapProductCategoriesHref": "/les-categories-de-produit-les-plus-populaires",
  "footerSitemapTopProducts": "Nos comparatifs",
  "footerSitemapTopProductsHref": "/nos-comparatifs",
  "footerManoExpress": "D\xE9couvrir ManoExpress",
  "footerManoExpressHref": "/lp/manoexpress-3056",
  "footerManoExpressHrefB2B": "/lp/manoexpress-3089",
  "footerToTopButtonAriaLabel": "Remonter",
  "footerTrustProSubtitle": "Vos grandes marques \xE0 prix n\xE9goci\xE9 sont ici.",
  "footerTrustProTitle": "Vous \xEAtes un professionnel ?",
  "titleApp": "L'application ManoMano",
  "titleProApp": "D\xE9couvrez l\u2019application ManoManoPro *"
});